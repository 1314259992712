<script setup lang="ts">
    import { truthyRule } from '@/shared';

    type Props = {
        actionColor?: string;
        message?: string;
        confirmText?: string;
        denyText?: string;
        requireInput?: boolean;
        inputLabel?: string;
    };

    withDefaults(defineProps<Props>(), {
        actionColor: 'red',
        message: 'Are you sure?',
        confirmText: 'Yes',
        denyText: 'No',
        requireInput: false,
        inputLabel: 'input',
    });

    const userInput = defineModel<string>('userInput', { default: '' });
    const rules = truthyRule('Required');

    type Emits = {
        confirm: [];
        deny: [];
        close: [];
    };
    defineEmits<Emits>();
</script>

<template>
    <VDropdown :distance="6" @hide="$emit('close')">
        <slot></slot>

        <!-- This will be the content of the popover -->
        <template v-slot:popper>
            <v-card color="secondary" elevation="8" variant="outlined" class="bg-surface-container-high">
                <v-card-title class="text-h4 font-weight-regular d-flex align-center gap-sm on-surface">
                    <v-icon icon="mdi-alert-circle-outline" :color="actionColor" />
                    <slot name="message">{{ message }}</slot>
                </v-card-title>
                <v-card-text v-if="requireInput">
                    <slot name="input">
                        <v-text-field
                            v-model="userInput"
                            autofocus
                            :label="inputLabel"
                            :rules="rules"
                            density="compact"
                            hide-details
                            variant="filled"
                            color="primary"
                            class="bg-surface-container-high"
                        />
                    </slot>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-around">
                    <slot name="deny">
                        <v-btn
                            v-close-popper
                            color="grey"
                            variant="tonal"
                            rounded="sm"
                            size="small"
                            @click="$emit('deny')"
                        >
                            <slot name="denyText">{{ denyText }}</slot>
                        </v-btn>
                    </slot>

                    <slot name="confirm">
                        <v-btn
                            v-close-popper
                            :color="actionColor"
                            variant="tonal"
                            rounded="sm"
                            size="small"
                            @click="$emit('confirm')"
                        >
                            <slot name="confirmText">{{ confirmText }}</slot>
                        </v-btn>
                    </slot>
                </v-card-actions>
            </v-card>
        </template>
    </VDropdown>
</template>
