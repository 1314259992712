<script setup lang="ts">
    import { ApiActionButton, RenderResultErrors } from '@/components';
    import LinkButton from '@/components/LinkButton.vue';
    import LoaderOverlay from '@/components/LoaderOverlay.vue';
    import { useCommand } from '@/features/api';
    import { notifyNotImplemented, useDialogWidth } from '@/shared/hooks';
    import { computed, toRef } from 'vue';
    import { useInvoice, useInvoiceStatusUiConfig, useInvoiceStripeUrls } from '../../hooks';
    import InvoiceStatusChip from '../InvoiceStatusChip.vue';
    import ViewInvoiceDialogActions from './ViewInvoiceDialogActions.vue';

    type Props = {
        invoiceId: string;
    };

    const props = defineProps<Props>();

    const { isLoading, error, invoice } = useInvoice(toRef(() => props.invoiceId));
    const { invoiceStripeUrls } = useInvoiceStripeUrls(
        toRef(() => props.invoiceId),
    );

    const { isLoading: markPaidLoading, mutateAsync: apiMarkPaid } = useCommand('invoice/ADMIN_MARK_PAID', {
        onSuccess: () => {
            close();
        },
    });

    const commandLoading = computed(() => markPaidLoading.value);

    const modelValue = defineModel<boolean>();
    const dialogWidth = useDialogWidth({ md: 0.75, lg: 0.6, xl: 0.6 });
    const invoiceUiCOnfig = useInvoiceStatusUiConfig(toRef(() => invoice?.value?.status));

    function close() {
        modelValue.value = false;
    }

    function payInvoice() {
        notifyNotImplemented('Pay Invoice');
    }

    async function adminMarkPaid() {
        await apiMarkPaid({ params: { invoiceId: props.invoiceId } });
    }
</script>
<template>
    <v-dialog v-model="modelValue" :width="dialogWidth">
        <v-card v-if="invoice" class="bg-surface-container" rounded="md">
            <v-card-title class="pb-0 d-flex align-center justify-space-between">
                <span class="text-h2">
                    Invoice <code class="text-grey">#</code>
                    <code class="text-blue-darken-2">{{ invoice.invoiceNumber }}</code>
                </span>
                <span>
                    <InvoiceStatusChip :status="invoice.status" />
                </span>
            </v-card-title>
            <v-card-title class="d-flex align-center mb-1 py-0">
                <span class="text-h4 emphasis-medium">Order</span>
                <span class="text-h4 emphasis-medium align-self-stretch">
                    <v-divider thickness="2px" vertical class="mx-2 on-surface emphasis-low" />
                </span>
                <span class="text-h4 emphasis-medium">Due: {{ $filters.date(invoice.dueBy) }}</span>
                <v-spacer />
                <IfCapability capability="billing:manage:any">
                    <ApiActionButton
                        v-if="invoice.canBeReissued"
                        endpoint="invoice/REISSUE_INVOICE"
                        :args="{ params: { invoiceId: invoice.id } }"
                        kind="button"
                    >
                        <template v-slot:button="slotProps">
                            <v-btn
                                v-if="invoice.canBeReissued"
                                :loading="slotProps.loading"
                                class="text-capitalize"
                                size="small"
                                color="secondary"
                                rounded="sm"
                                variant="tonal"
                                elevation=""
                                prepend-icon="mdi-plus"
                                @click="slotProps.onClick"
                            >
                                Re-issue Invoice
                            </v-btn>
                        </template>
                    </ApiActionButton>
                </IfCapability>

                <LinkButton
                    v-if="invoice.reIssuedToId"
                    size="small"
                    @click="$triggerDialog('viewInvoice', { invoiceId: invoice.reIssuedToId })"
                >
                    Reissued
                </LinkButton>
                <LinkButton
                    v-if="invoiceStripeUrls?.pdfUrl"
                    size="small"
                    :href="invoiceStripeUrls?.pdfUrl"
                    target="_blank"
                >
                    Download PDF
                </LinkButton>
            </v-card-title>
            <template v-if="invoiceUiCOnfig && invoiceUiCOnfig.alertText">
                <v-alert
                    variant="tonal"
                    class="text-h4"
                    rounded="0"
                    :icon="invoiceUiCOnfig.icon"
                    :color="invoiceUiCOnfig.color"
                >
                    {{ invoiceUiCOnfig.alertText }}
                    <template v-if="invoice.paidAt">
                        {{ $filters.date(invoice.paidAt!) }}
                    </template>
                </v-alert>
            </template>

            <v-divider thickness="2px" />
            <v-card-text style="min-height: 50vh" class="bg-surface d-flex flex-column">
                <div class="mt-4 mb-8">
                    <v-row>
                        <v-col cols="12" md="6" class="d-flex gap-sm align-center">
                            <div class="emphasis-9">Order:</div>
                            <div>
                                <LinkButton
                                    size="small"
                                    @click="$triggerDialog('viewOrder', { orderId: invoice.orderId })"
                                >
                                    {{ invoice.orderName }}
                                </LinkButton>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" class="d-flex gap-sm align-center">
                            <div class="emphasis-9">Company:</div>
                            <div>
                                <LinkButton size="small" @click="$notImplemented('View Company')">
                                    {{ invoice.companyName }}
                                </LinkButton>
                            </div>
                        </v-col>
                        <v-col cols="6">
                            <div class="emphasis-9">Issued On:</div>
                            <div class="text-body-1">{{ $filters.date(invoice.createdAt) }}</div>
                        </v-col>
                        <v-col cols="6">
                            <div class="emphasis-9">For Period:</div>
                            <div class="text-body-1">
                                {{ $filters.date(invoice.periodStart) }} - {{ $filters.date(invoice.periodEnd) }}
                            </div>
                        </v-col>
                    </v-row>
                </div>

                <!-- <v-divider class="mb-2" thickness="2" /> -->
                <v-table class="rounded-sm bg-surface-container line-item-table flex-shrink-0" density="comfortable">
                    <thead class="bg-surface-container-high">
                        <tr class="emphasis-8">
                            <th class="text-left">Type</th>
                            <th class="text-left">Price</th>
                            <th class="text-left">Quantity</th>
                            <th class="text-left">Total</th>
                            <th class="text-left">Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(lineItem, i) in invoice.lineItems" :key="i">
                            <td>{{ lineItem.kind }}</td>
                            <td>{{ $filters.currency(lineItem.unitValue) }}</td>
                            <td>{{ $filters.number(lineItem.units) }}</td>
                            <td>{{ $filters.currency(lineItem.totalAmount) }}</td>
                            <td>{{ lineItem.description }}</td>
                        </tr>
                        <tr>
                            <td />
                            <td colspan="2" class="text-right emphasis-8">Total Amount</td>
                            <td class="font-weight-medium">{{ $filters.currency(invoice.amountDue) }}</td>
                            <td />
                        </tr>
                    </tbody>
                </v-table>
            </v-card-text>
            <ViewInvoiceDialogActions
                :invoice="invoice"
                :loading="isLoading || commandLoading"
                @click:close="close"
                @click:play="payInvoice"
                @click:mark-paid="adminMarkPaid"
            >
                <LinkButton
                    v-if="invoiceStripeUrls?.hostedUrl"
                    size="large"
                    :href="invoiceStripeUrls?.hostedUrl"
                    target="_blank"
                >
                    Open
                </LinkButton>
            </ViewInvoiceDialogActions>
            <RenderResultErrors :result="error" />
        </v-card>
        <v-card v-else class="p-relative">
            <LoaderOverlay :active="isLoading" />
        </v-card>
    </v-dialog>
</template>

<style lang="scss" scoped>
.line-item-table {
    border: 1px solid rgb(var(--v-theme-surface-container-highest));

    th {
        color: rgb(var(--v-theme-on-surface-container-low));
    }

    td {
        color: rgb(var(--v-theme-on-surface-container));
    }
}
</style>
