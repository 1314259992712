<script setup lang="ts">
    import { ref, watchEffect } from 'vue';

    import { IndustryAutocomplete } from '@/features/industries/components';
    import { LATITUDE_RULES, LONGITUDE_RULES, notNegativeNumberRule, truthyRule } from '@/shared';
    import type { CreateScreenRequest } from '../api';

    type Emits = {
        'update:formRef': [value: VuetifyForm];
    };

    const formData = defineModel<CreateScreenRequest>({ required: true });
    const emits = defineEmits<Emits>();

    const form = ref<VuetifyForm>();
    const rules = ref({
        title: truthyRule('Title is required'),
        business: truthyRule('Business is required'),
        address: truthyRule('Address is required'),
        excerpt: truthyRule('Excerpt is required'),
        industry: truthyRule('Industry must be selected for screens'),
        dailyViewsRule: notNegativeNumberRule('Avg Daily Views must be a positive number'),
        maxDailyPlays: notNegativeNumberRule('Max Daily Plays must be a positive number'),
        startDate: [(v: Date | null) => !!v || 'Campaign Start is required'],
        company: [(v: number | null) => !!v || 'Company is required'],
        latitude: [...truthyRule('Latitude is required'), ...LATITUDE_RULES],
        longitude: [...truthyRule('Longitude is required'), ...LONGITUDE_RULES],
    });

    watchEffect(() => {
        if (form.value) {
            emits('update:formRef', form.value);
        }
    });
</script>

<template>
    <v-form ref="form">
        <v-row align="center">
            <v-col cols="12" class="text-center">
                <h2>Details</h2>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4">
                <v-text-field
                    v-model="formData.title"
                    :rules="rules.title"
                    label="Title"
                    density="comfortable"
                    variant="outlined"
                    color="primary"
                />
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4">
                <v-text-field
                    v-model="formData.business"
                    :rules="rules.business"
                    density="comfortable"
                    label="Business Name"
                    variant="outlined"
                    color="primary"
                />
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4">
                <v-text-field
                    v-model="formData.avgDailyViews"
                    :rules="rules.dailyViewsRule"
                    type="number"
                    density="comfortable"
                    label="Avg Daily Views"
                    variant="outlined"
                    color="primary"
                />
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                    v-model="formData.address"
                    :rules="rules.address"
                    density="comfortable"
                    label="Business Address"
                    variant="outlined"
                    color="primary"
                />
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
                <v-text-field
                    v-model="formData.excerpt"
                    :rules="rules.excerpt"
                    label="Excerpt"
                    variant="outlined"
                    density="comfortable"
                    color="primary"
                    hint="A brief description of the screen"
                    persistent-hint
                />
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
                <v-textarea
                    v-model="formData.notes"
                    label="Notes"
                    variant="outlined"
                    density="comfortable"
                    color="primary"
                    hint="Notes about the screen, location, owner name, asset information ...etc"
                    persistent-hint
                />
            </v-col>
            <v-col cols="12" class="text-center mt-n3">
                <h2>Configuration</h2>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
                <IndustryAutocomplete
                    v-model="formData.metadata.industryIds"
                    :rules="rules.industry"
                    class="bg-shaded-1"
                    density="comfortable"
                    hint="Location-specific industries. The industries that will be excluded from playing on this screen"
                    label="Location Industry Sectors"
                    persistent-hint
                />
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4">
                <v-text-field
                    v-model="formData.maxPlaysPerDay"
                    :rules="rules.maxDailyPlays"
                    type="number"
                    density="comfortable"
                    label="Maximum Daily Plays"
                    hint="The maximum number of plays this screen can support in a day. 0 for unlimited"
                    persistent-hint
                    variant="outlined"
                    color="primary"
                />
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6" class="d-flex justify-space-around gap-md">
                <v-text-field
                    v-model="formData.position.lat"
                    :rules="rules.latitude"
                    type="number"
                    density="comfortable"
                    label="Latitude"
                    placeholder="-90 to 90"
                    variant="outlined"
                    color="primary"
                />
                <v-text-field
                    v-model="formData.position.long"
                    :rules="rules.longitude"
                    type="number"
                    density="comfortable"
                    label="Longitude"
                    placeholder="-180 to 180"
                    variant="outlined"
                    color="primary"
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<style scoped lang="scss">
.fit-content {
    width: fit-content;
}

:deep(.v-data-table__tr:has(input[type="checkbox"]:checked)) {
    background-color: rgb(var(--v-theme-surface-container-low));
}
</style>
