import { useApi } from '@/features/api/hooks';
import type { SelectItem } from '@/shared/types';
import { computed } from 'vue';

export function useIndustries() {
    const { isLoading, data } = useApi('industry/GET_INDUSTRIES', (endpoint, fetcher) => {
        return fetcher(endpoint);
    }, { staleTime: 30000 });

    /** Array of { title, value } entries for categories */
    const industrySelectItems = computed(() => {
        return data.value?.map((category) => {
            return { title: category.name, value: category.id };
        }).sort((a, b) => a.title.localeCompare(b.title));
    });

    const industriesSelectMap = computed(() => {
        const map = new Map<number, SelectItem[]>();
        data.value?.forEach((sector) => {
            map.set(
                sector.id,
                sector.industries.map((industry) => {
                    return { title: industry.name, value: industry.id };
                }).sort((a, b) => a.title.localeCompare(b.title)),
            );
        });
        return map;
    });

    return {
        isLoading,
        industrySectors: data,
        industrySelectItems,
        industriesSelectMap,
    };
}
