<script setup lang="ts">
    import { computed, ref, watch } from 'vue';

    import type { ScreenResponse } from '../../api';
    import ScreenListCard from '../ScreensDataView/ScreenListCard.vue';

    type Props = {
        isLoading: boolean;
        hasFilters: boolean;
        screens: undefined | ScreenResponse[];
    };

    type Emits = {
        'clear-filters': [];
    };

    defineEmits<Emits>();
    const props = defineProps<Props>();

    const selectedIds = defineModel<number[]>('selectedIds', { default: () => [] });

    const selected = ref<Set<number>>(new Set());
    const sortedScreens = computed(() => {
        if (!props.screens) {
            return [];
        }

        const screens = [...props.screens];
        const selectedScreens = screens.filter(screen => selected.value.has(screen.id));
        const unselectedScreens = screens.filter(screen => !selected.value.has(screen.id));

        return [...selectedScreens, ...unselectedScreens];
    });

    function clickScreen(item: ScreenResponse) {
        if (selected.value.has(item.id)) {
            selected.value.delete(item.id);
        } else {
            selected.value.add(item.id);
        }

        selectedIds.value = Array.from(selected.value);
    }

    watch(selectedIds, (newVal, oldVal) => {
        if (newVal !== oldVal) {
            selected.value = new Set(newVal);
        }
    }, { immediate: true });
</script>

<template>
    <perfect-scrollbar class="h-100">
        <v-data-iterator
            v-if="screens"
            :items="sortedScreens"
            :items-per-page="0"
            class="px-4 my-2 cards-data-iterator d-flex flex-column gap-md"
        >
            <template v-slot="{ items }">
                <div class="d-flex flex-column gap-md">
                    <v-slide-y-transition group class="gap-md">
                        <template v-for="item in items" :key="item.raw.id">
                            <ScreenListCard
                                :screen="item.raw"
                                :selected="selected.has(item.raw.id)"
                                @click="clickScreen(item.raw)"
                            />
                            <br>
                        </template>
                    </v-slide-y-transition>
                </div>
            </template>
        </v-data-iterator>
    </perfect-scrollbar>
</template>

<style lang="scss" scoped>
:deep(.cards-data-iterator) {
    br {
        display: none;
    }
}

.screen-card {
    transition: transform 0.2s ease-in-out;
}
.selected-screen {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    border: 2px solid rgb(var(--v-theme-secondary)) !important;
}
</style>
