import type { Ref } from 'vue';
import { ref, toRaw, watch } from 'vue';
import type { CreateScreenRequest, ScreenResponse } from '../api';
import { useScreen } from './useScreen';

export function useEditableScreen(screenId: number | Ref<number>) {
    const { isLoading, errors, screen } = useScreen(screenId);

    const editable = ref<CreateScreenRequest | null>(null);

    watch(screen, (newVal) => {
        if (newVal && editable.value === null) {
            setEditable(toRaw(newVal));
        }
    }, { immediate: true });

    function setEditable(value: ScreenResponse) {
        editable.value = {
            ...structuredClone(value),
            metadata: {
                industryIds: value.metadata.industries.map((industry) => industry.industryId),
            },
        };
    }

    function reset() {
        if (screen.value) {
            setEditable(screen.value);
        }
    }

    return {
        isLoading,
        screen: editable,
        errors,
        reset,
    };
}
