<script setup lang="ts">
    import { EmptyResultsCard } from '@/components/DataTable';
    import IndustrySectorIcon from '@/features/industries/components/IndustrySectorIcon.vue';
    import { computed, ref, watch, watchEffect } from 'vue';

    import type { Header } from 'vue3-easy-data-table';
    import type { ScreenResponse } from '../../api';

    type Props = {
        isLoading: boolean;
        hasFilters: boolean;
        screens: undefined | ScreenResponse[];
    };

    type Emits = {
        'clear-filters': [];
    };

    defineEmits<Emits>();
    const props = defineProps<Props>();

    const selectedIds = defineModel<number[]>('selectedIds', { default: () => [] });
    const dataFooter = defineModel<EasyDataTableFooter>('dataFooter');

    const headers: Header[] = [
        { text: 'selected', value: 'selected', sortable: true },
        { text: 'Name', value: 'title', sortable: true },
        { text: 'Impressions', value: 'avgDailyViews', sortable: true },
    ];

    const dataTableRef = ref<EasyDataTableFooter>();
    const selected = ref<Set<number>>(new Set());

    const sortedScreens = computed(() => {
        if (!props.screens) {
            return [];
        }

        const screens = [...props.screens];
        const selectedScreens = screens.filter(screen => selected.value.has(screen.id));
        const unselectedScreens = screens.filter(screen => !selected.value.has(screen.id));

        return [...selectedScreens, ...unselectedScreens];
    });

    function clickRow(item: ScreenResponse) {
        if (selected.value.has(item.id)) {
            selected.value.delete(item.id);
        } else {
            selected.value.add(item.id);
        }

        selectedIds.value = Array.from(selected.value);
    }

    watch(selectedIds, (newVal, oldVal) => {
        if (newVal !== oldVal) {
            selected.value = new Set(newVal);
        }
    }, { immediate: true });

    watchEffect(() => {
        if (dataTableRef.value) {
            dataFooter.value = dataTableRef.value;
        }
    });
</script>

<template>
    <perfect-scrollbar>
        <EasyDataTable
            ref="dataTableRef"
            :prevent-context-menu-row="false"
            :items="sortedScreens"
            :headers="headers"
            :loading="isLoading"
            :rows-items="[10, 25, 50]"
            :rows-per-page="50"
            alternating
            body-row-class-name="cursor-pointer"
            table-class-name="easy-data-table-custom contained"
            theme-color="rgb(var(--v-theme-primary))"
            class="d-flex flex-column"
            hide-footer
            @click-row="clickRow"
        >
            <template v-slot:header-selected>
                <!-- <v-checkbox :value="header.isSelected" /> -->
            </template>
            <template v-slot:item-selected="{ id }">
                <v-checkbox
                    :model-value="selected.has(id)"
                    hide-details
                    density="compact"
                    :class="selected.has(id) ? 'selected' : undefined"
                />
            </template>
            <template v-slot:item-title="{ title, metadata }">
                <div style="max-width: 185px;" class="text-truncate" :title="title">
                    <div class="no-select">
                        {{ title }}
                    </div>
                    <!-- <v-chip class="mt-1" color="orange" text-color="primary" size="small" density="comfortable">
                        {{ metadata.category }}
                    </v-chip> -->
                    <div class="text-caption text-light-blue no-select">
                        <IndustrySectorIcon :industries="metadata.industries" size="h4" />
                        {{ metadata.industries[0].sectorName }}
                    </div>
                </div>
            </template>
            <template v-slot:item-avgDailyViews="{ avgDailyViews }">
                <div class="no-select">
                    {{ $filters.number(avgDailyViews) }}
                </div>
            </template>

            <template v-slot:empty-message>
                <EmptyResultsCard
                    :has-filters="hasFilters"
                    empty-message="No Locations Found"
                    @clear-filters="$emit('clear-filters')"
                />
            </template>
        </EasyDataTable>
    </perfect-scrollbar>
</template>

<style scoped lang="scss">
:deep(.vue3-easy-data-table__main) {
    table {
        border-collapse: collapse;
    }
}

:deep(.vue3-easy-data-table__body) {
    tr:has(td > .selected) {
        background-color: rgba(var(--v-theme-brighten-base), 0.075) !important;
        border-left: 2px solid rgba(var(--v-theme-secondary), 0.7);

        td {
            background-color: transparent !important;
        }
    }

    // Selected item styling
    tr.even-row:has(td > .selected) {
        background-color: rgba(var(--v-theme-brighten-base), 0.15) !important;
        border-left: 2px solid rgba(var(--v-theme-secondary), 0.9);

        td {
            background-color: transparent !important;
        }
    }

    tr {
        border: 2px solid transparent;
    }

    tr:hover {
        border: 2px solid rgba(var(--v-theme-primary), 0.3);

        td {
            border-top: 2px solid rgba(var(--v-theme-primary), 0.3);
        }
    }
}
</style>
