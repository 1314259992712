<script setup lang="ts">
    import RenderResultErrors from '@/components/RenderResultErrors.vue';
    import { useCommand } from '@/features/api';
    import { useDialogWidth } from '@/shared/hooks';

    import { ref, toRef } from 'vue';
    import { useEditableScreen } from '../hooks';
    import ScreenForm from './ScreenForm.vue';

    type Props = {
        screenId: number;
    };

    const props = defineProps<Props>();
    const modelValue = defineModel<boolean>();
    const dialogWidth = useDialogWidth({ lg: 0.75, xl: 0.75 });

    const { screen, isLoading } = useEditableScreen(toRef(() => props.screenId));

    const { isLoading: saveLoading, mutateAsync, error } = useCommand('screens/UPDATE_SCREEN', {
        invalidates: ['screens/'],
        onSettled(data, error) {
            if (!error) {
                modelValue.value = false;
            }
        },
    });

    const formRef = ref<VuetifyForm>();

    async function save() {
        if (!screen.value) return;
        if (!(await formRef.value?.validate())?.valid) return;

        await mutateAsync({
            params: { screenId: props.screenId },
            request: screen.value,
        });
    }
</script>

<template>
    <v-dialog v-model="modelValue" :width="dialogWidth" persistent>
        <v-card :loading="isLoading">
            <v-card-title class="text-center pt-2 bg-surface-container">
                <span class="text-h2">Edit Screen</span>
            </v-card-title>
            <v-divider thickness="2px" />
            <v-card-text style="min-height: 50vh" class="mt-4">
                <ScreenForm v-if="screen" v-model="screen" v-model:form-ref="formRef" />
            </v-card-text>
            <v-card-actions>
                <v-btn color="grey" size="large" @click="() => (modelValue = false)">
                    Cancel
                </v-btn>
                <v-spacer />
                <v-btn color="primary" size="large" :loading="saveLoading" @click="save">
                    Save
                </v-btn>
            </v-card-actions>
            <RenderResultErrors :result="error" />
        </v-card>
    </v-dialog>
</template>

<style scoped lang="scss">
.fit-content {
    width: fit-content;
}

:deep(.v-data-table__tr:has(input[type="checkbox"]:checked)) {
    background-color: rgb(var(--v-theme-surface-container-low));
}
</style>
