<script setup lang="ts" generic="TEndpoint extends CommandEndpoint">
    import type { CommandEndpoint } from '@/features/api/endpoints';
    import { useCommand } from '@/features/api/hooks';
    import type { ApiCallRequest } from '@/features/api/utilities/types';
    import type { ComponentProps } from '@/features/globalDialogs/types';
    import { ref, watch } from 'vue';
    import ConfirmPopover from './ConfirmPopover.vue';
    import LoaderOverlay from './LoaderOverlay.vue';

    type ApiCallRequestFunc<TEndpoint extends CommandEndpoint> = (input: string) => ApiCallRequest<TEndpoint>;

    type Props = {
        endpoint: TEndpoint;
        args: ApiCallRequest<TEndpoint> | ApiCallRequestFunc<TEndpoint>;
        disabled?: boolean;
        confirm?: Partial<ComponentProps<typeof ConfirmPopover>>;
        icon?: string;
        iconColor?: string;
        kind: 'list-item' | 'button';
    };

    type Emits = {
        /** When the loading status changes */
        'update-loading': [boolean];
        deny: [];
        confirm: [];
        click: [];
    };

    /** Used to toggle the parent menu  persistence when a confirm popover is open due to an inability to avoid menus closing when sub-content is clocked */
    const parentPersistence = defineModel<boolean>('parentPersistence', { default: false });
    const props = withDefaults(defineProps<Props>(), {
        confirm: undefined,
        disabled: false,
        icon: undefined,
        iconColor: undefined,
        kind: 'list-item',
    });
    const emits = defineEmits<Emits>();

    const userInput = ref('');
    const { isLoading, mutateAsync } = useCommand(props.endpoint, {
        onSettled() {
            // Since this component can close before we finish, we want to ensure we emit this in this closure which is preserved
            // Otherwise the parent may get stuck in a perpetual loading state
            emits('update-loading', false);
        },
    });

    async function handleClicked() {
        try {
            if (typeof props.args === 'function') {
                await mutateAsync(props.args(userInput.value));
            } else {
                await mutateAsync(props.args);
            }
        } finally {
            emits('update-loading', false);
            emits('click');
        }
    }

    function handleDeny() {
        parentPersistence.value = false;
        emits('update-loading', false);
        emits('click');
        emits('deny');
    }

    function handleConfirm() {
        void handleClicked();
        emits('confirm');
    }
    function handlePopoverClose() {
        // Avoids rendering race condition where the click event hits the parent before/after the popover close event
        // Causing the parent to close the menu when it shouldn't
        setTimeout(() => {
            parentPersistence.value = false;
        }, 0);
    }

    function handlePopoverOpen() {
        parentPersistence.value = true;
    }

    watch(isLoading, (value) => {
        emits('update-loading', value);
    });
</script>

<template>
    <template v-if="props.confirm">
        <ConfirmPopover
            v-bind="confirm"
            v-model:user-input="userInput"
            @confirm="handleConfirm"
            @deny="handleDeny"
            @close="handlePopoverClose"
            @show="handlePopoverOpen"
        >
            <v-list-item v-if="kind === 'list-item'" class="p-relative" :disabled="disabled" @click.stop="">
                <LoaderOverlay :active="isLoading" color="secondary" size="auto" />
                <v-list-item-title>
                    <v-icon v-if="icon" :color="iconColor" :icon="icon" />
                    <slot />
                </v-list-item-title>
            </v-list-item>
            <slot v-else name="button" :loading="isLoading">
                <v-btn :disabled="disabled" class="p-relative" :loading="isLoading" @click.stop="">
                    <v-icon v-if="icon" :color="iconColor" :icon="icon" />
                    <slot />
                </v-btn>
            </slot>
        </ConfirmPopover>
    </template>
    <template v-else>
        <v-list-item v-if="kind === 'list-item'" class="p-relative" :disabled="disabled" @click="handleClicked">
            <LoaderOverlay :active="isLoading" color="secondary" size="auto" />
            <v-list-item-title>
                <v-icon v-if="icon" :color="iconColor" :icon="icon" />
                <slot />
            </v-list-item-title>
        </v-list-item>
        <slot v-else name="button" :on-click="handleClicked" :loading="isLoading">
            <v-btn :disabled="disabled" class="p-relative" :loading="isLoading" @click="handleClicked">
                <v-icon v-if="icon" :color="iconColor" :icon="icon" />
                <slot />
            </v-btn>
        </slot>
    </template>
</template>
