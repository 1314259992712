/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from '@/plugins';

// Components
import App from './App.vue';
import AppUnsupported from './AppUnsupported.vue';
import '@/scss/style.scss';

// Composables
import { createApp } from 'vue';

import { createI18n } from 'vue-i18n';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import 'maplibre-gl/dist/maplibre-gl.css';
import { VueQueryPlugin } from 'vue-query';
import { localeMessages } from './locales';

import 'vue3-easy-data-table/dist/style.css';

import Vue3EasyDataTable from 'vue3-easy-data-table';
import { setFetchTokenProvider } from './features/api/utilities';
import { useAuthStore } from './features/auth';
import { TokenProviderImpl } from './features/auth/tokenProvider';

import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
// eslint-disable-next-line import/no-unresolved, @typescript-eslint/consistent-type-imports
import { FloatingVueConfig } from 'floating-vue/dist/config';
import IfCapability from './components/Capabilities/IfCapability.vue';
import IfRole from './components/Capabilities/IfRole.vue';
import vuetify from './plugins/vuetify';
if (!Promise.withResolvers) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const app = createApp(AppUnsupported);
    app.use(vuetify);
    app.mount('#app');
} else {
    const floatingVueConfig: FloatingVueConfig = {
        themes: {
            dropdown: {
                delay: 0,
            },
            menu: {
                delay: {
                    show: 0,
                    hide: 300,
                },
            },
        },
    };

    const tokenProvider = new TokenProviderImpl();

    const i18n = createI18n({
        locale: 'en',
        messages: localeMessages,
        silentTranslationWarn: true,
        silentFallbackWarn: true,
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const app = createApp(App);

    // app.use(VueMaplibreGl);

    registerPlugins(app);

    app.component('EasyDataTable', Vue3EasyDataTable);
    app.component('IfCapability', IfCapability);
    app.component('IfRole', IfRole);
    app.use(PerfectScrollbar);
    app.use(i18n);
    app.use(VueQueryPlugin);

    app.use(FloatingVue, floatingVueConfig);

    // Kinda jank, probably a better flow to do this somewhere
    tokenProvider.registerDeferredSource(useAuthStore);
    setFetchTokenProvider(tokenProvider);

    app.mount('#app');
}
