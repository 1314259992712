import type { BoundingBox } from '@/features/gis';
import { filterBySearch } from '@/shared/utilities';
import type { Ref } from 'vue';
import { computed } from 'vue';
import type { ScreenResponse } from '../api';

type Props = {
    screens: ScreenResponse[] | undefined;
    excludeSectors: number[] | undefined;
    search: string | undefined;
    visibleBounds: BoundingBox | undefined;
    showOnlyVisible: boolean;
};

export function filterByBoundingBox(screens: ScreenResponse[], boundingBox: BoundingBox) {
    const [sw, ne] = boundingBox;

    return screens.filter((s) => {
        return (
            s.position.lat >= sw[1]
            && s.position.lat <= ne[1]
            && s.position.long >= sw[0]
            && s.position.long <= ne[0]
        );
    });
}

export function useFilteredScreens(props: Ref<Props>) {
    const screens = computed(() => props.value.screens);
    const excludeSectors = computed(() => props.value.excludeSectors || []);
    const search = computed(() => props.value.search);
    const visibleBounds = computed(() => props.value.visibleBounds);
    const showOnlyVisible = computed(() => props.value.showOnlyVisible);

    const filteredScreens = computed(() => {
        if (!screens.value) {
            return [];
        }

        let filtered = screens.value;

        if (excludeSectors.value && excludeSectors.value.length) {
            filtered = filtered.filter((screen) => !excludeSectors.value?.includes(screen.sectorId));
        }

        if (search.value) {
            filtered = filterBySearch(filtered, search.value, { imageThumbnailUrl: { maxDepth: 0 } });
        }

        if (visibleBounds.value && showOnlyVisible.value) {
            filtered = filterByBoundingBox(filtered, visibleBounds.value);
        }

        return filtered;
    });

    return filteredScreens;
}
