<script setup lang="ts">
    import RenderResultErrors from '@/components/RenderResultErrors.vue';
    import { useCommand } from '@/features/api';
    import { useDialogWidth } from '@/shared/hooks';

    import { ref } from 'vue';
    import type { CreateScreenRequest } from '../api';
    import ScreenForm from './ScreenForm.vue';

    const modelValue = defineModel<boolean>();
    const dialogWidth = useDialogWidth({ lg: 0.75, xl: 0.75 });

    const formData = ref<CreateScreenRequest>({
        title: '',
        business: '',
        businessLocation: null,
        screenLocation: null,
        excerpt: null,
        notes: null,
        avgDailyViews: 0,
        maxPlaysPerDay: 0,
        url: null,
        address: null,
        position: {
            lat: null!,
            long: null!,
        },
        imageUrl: null,
        imageThumbnailUrl: null,
        metadata: {
            industryIds: [],
        },
    });

    const { isLoading, mutateAsync, error } = useCommand('screens/CREATE_SCREEN', {
        invalidates: ['screens/'],
        onSettled(data, error) {
            if (!error) {
                modelValue.value = false;
            }
        },
    });

    const formRef = ref<VuetifyForm>();

    async function save() {
        if (!(await formRef.value?.validate())?.valid) return;

        await mutateAsync({
            request: formData.value,
        });
    }
</script>

<template>
    <v-dialog v-model="modelValue" :width="dialogWidth" persistent>
        <v-card>
            <v-card-title class="text-center pt-2 bg-surface-container">
                <span class="text-h2">Create Screen</span>
            </v-card-title>
            <v-divider thickness="2px" />
            <v-card-text style="min-height: 50vh" class="mt-4">
                <ScreenForm v-model="formData" v-model:form-ref="formRef" />
            </v-card-text>
            <v-card-actions>
                <v-btn color="grey" size="large" @click="() => (modelValue = false)">
                    Cancel
                </v-btn>
                <v-spacer />
                <v-btn color="primary" size="large" :loading="isLoading" @click="save">
                    Save
                </v-btn>
            </v-card-actions>
            <RenderResultErrors :result="error" />
        </v-card>
    </v-dialog>
</template>

<style scoped lang="scss">
.fit-content {
    width: fit-content;
}

:deep(.v-data-table__tr:has(input[type="checkbox"]:checked)) {
    background-color: rgb(var(--v-theme-surface-container-low));
}
</style>
