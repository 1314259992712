<script setup lang="ts">
    import { computed, ref, watch } from 'vue';

    import { AutocompleteGroup } from '@/components/AutocompleteGroup';
    import { arraysEqual } from '@/shared/utilities/arraysEqual';
    import { useIndustries } from '../hooks';
    import IndustrySectorIcon from './IndustrySectorIcon.vue';

    type IndustrySelectItem = {
        sectorName: string;
        industryName: string;
        sectorId: number;
        industryId: number;
        title: string;
        value: string;
    };

    const modelValue = defineModel<number[]>({ default: () => [] });

    const localSelected = ref<IndustrySelectItem[]>([]);

    type Props = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rules?: ((value: any) => string | boolean)[];
    };

    withDefaults(defineProps<Props>(), {
        rules: undefined,
    });

    const { isLoading, industrySectors } = useIndustries();

    const flattenedIndustries = computed(() => {
        return industrySectors.value?.flatMap((sector) => {
            return sector.industries.map((industry) => ({
                sectorName: sector.name,
                industryName: industry.name,
                sectorId: sector.id,
                industryId: industry.id,
                title: `${industry.name}`,
                value: `${industry.id}`,
            }));
        });
    });

    const groupedCategoryItems = computed(() => {
        const groups = flattenedIndustries.value?.reduce((acc, item) => {
            if (!acc[item.sectorName]) {
                acc[item.sectorName] = [];
            }

            acc[item.sectorName].push(item);

            return acc;
        }, {} as Record<string, IndustrySelectItem[]>);

        const groupedItems = Object.entries(groups || {}).map(([header, items]) => ({
            header,
            items,
        }));

        return groupedItems;
    });

    watch(modelValue, (newVal, oldVal) => {
        if (newVal !== oldVal && flattenedIndustries.value) {
            updateSelectedWithModal(newVal);
        }
    }, { immediate: true });

    watch(localSelected, (newVal, oldVal) => {
        if (newVal !== oldVal) {
            // Avoid feedback loops
            if (arraysEqual(modelValue.value, localSelected.value.map(x => x.industryId))) {
                return;
            }

            updateModalWithSelected(newVal);
        }
    });

    // Watch for when industries load, and update local state with modal value if it has not already been done
    watch(flattenedIndustries, (newVal, oldVal) => {
        if (newVal && newVal !== oldVal && localSelected.value.length === 0) {
            updateSelectedWithModal(modelValue.value);
        }
    });

    function updateSelectedWithModal(industryIds: number[]) {
        const selected = flattenedIndustries.value?.filter((item) => industryIds.includes(item.industryId));
        localSelected.value = selected || [];
    }

    function updateModalWithSelected(selected: IndustrySelectItem[]) {
        const selectedIds = selected.map((item) => item.industryId);
        modelValue.value = selectedIds;
    }
</script>

<template>
    <AutocompleteGroup
        v-model="localSelected"
        :items="groupedCategoryItems"
        color="primary"
        variant="outlined"
        label="Industries"
        prepend-inner-icon="mdi-magnify"
        :loading="isLoading"
        :rules="rules"
    >
        <template v-slot:item-header="{ item }">
            <IndustrySectorIcon :sector="item.header" size="h3" />
            {{ item.header }}
        </template>
        <template v-slot:item="{ item }">
            {{ item.title }}
        </template>
    </AutocompleteGroup>
</template>
