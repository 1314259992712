<script setup lang="ts" generic="TItem extends { title: string; value: string }">
    import { filterObject } from '@/shared/utilities';
    import { reactive, watch } from 'vue';
    import { computed, ref } from 'vue';

    // THis is a hacky, terrible, implementation of a grouped autocomplete
    // The performanc eimplications of heavy JSON.stringify abuse

    type ElementGroup<TItem> = {
        header: string;
        items: TItem[];
    };

    type Props<TItem> = {
        items: ElementGroup<TItem>[];
        color?: string;
        variant?: 'outlined' | 'plain' | 'filled' | 'underlined' | 'solo' | 'solo-inverted' | 'solo-filled';
        label?: string;
        loading?: boolean;
        prependInnerIcon?: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rules?: ((value: any) => string | boolean)[];
    };

    const props = withDefaults(defineProps<Props<TItem>>(), {
        color: 'primary',
        variant: 'outlined',
        label: '',
        loading: false,
        prependInnerIcon: '',
        rules: undefined,
    });

    const modelValue = defineModel<TItem[]>({ default: () => [] });

    const selectedItems = reactive<Map<string, TItem>>(new Map<string, TItem>());

    watch(modelValue, (newVal) => {
        if (!newVal || !newVal.length) {
            return;
        }

        if (newVal.every((item) => selectedItems.has(item.value))) {
            return;
        }

        selectedItems.clear();

        newVal.forEach((item) => {
            // @ts-expect-error Idk why it wants it as an unrefed value
            selectedItems.set(item.value, item);
        });
    }, { immediate: true });

    watch(selectedItems, (newVal) => {
        // @ts-expect-error Idk why it wants it as an unrefed value
        modelValue.value = Array.from(newVal.values());
    });

    const search = ref('');
    const filteredItems = computed(() => {
        if (!props.items) {
            return [];
        }

        if (!search.value) {
            return props.items;
        }

        return props.items.map((group) => {
            return {
                header: group.header,
                items: group.items.filter((item) => {
                    return filterObject(item, search.value);
                }),
            };
        }).filter((group) => group.items.length > 0);
    });

    function clickItem(item: TItem) {
        if (selectedItems.has(item.value)) {
            selectedItems.delete(item.value);
        } else {
            selectedItems.set(item.value, item as never);
        }
    }

    function removeItem(item: TItem | object) {
        selectedItems.delete((item as TItem).value);
    }

    function isSelected(item: TItem) {
        return selectedItems.has(item.value);
    }
</script>

<template>
    <v-autocomplete
        :model-value="modelValue"
        :items="filteredItems"
        :search="search"
        no-filter
        multiple
        :color="color"
        :variant="variant"
        :label="label"
        :loading="loading"
        :prepend-inner-icon="prependInnerIcon"
        :rules="rules"
        class="grouped-autocomplete"
        @update:search="search = $event"
    >
        <template v-slot:item="data">
            <v-list-item>
                <v-list-item-title>
                    <slot name="item-header" :item="data.item.raw" />
                </v-list-item-title>
            </v-list-item>
            <div class="">
                <v-list-item
                    v-for="(item, index) in data.item.raw.items"
                    :key="index"
                    class="grouped-autocomplete-item"
                    :class="isSelected(item) ? 'v-list-item--active text-primary' : ''"
                    @click="clickItem(item)"
                >
                    <v-list-item-subtitle>
                        <slot name="item" :item="item" />
                    </v-list-item-subtitle>
                </v-list-item>
            </div>
        </template>

        <template v-slot:selection="{ item }">
            <v-chip size="small" @click.stop.prevent="removeItem(item.raw)">{{ item.title }}</v-chip>
        </template>
    </v-autocomplete>
</template>

<style scoped lang="scss">
.grouped-autocomplete-item {
    padding-inline-start: calc(16px + 24px) !important;

    .v-list-item-subtitle {
        opacity: 0.8;
    }
}
</style>
