<script setup lang="ts">
    import { computed } from 'vue';

    type Props = {
        active: boolean;
        color?: string;
        size?: string | number;
    };

    const props = withDefaults(defineProps<Props>(), {
        color: 'primary',
        size: '64',
    });

    const spinnerSize = computed(() => {
        return props.size === 'auto' ? undefined : props.size;
    });
</script>

<template>
    <v-overlay contained :model-value="active" scrim="black" class="d-flex justify-center align-center">
        <v-progress-circular :color="color" indeterminate :size="spinnerSize" />
    </v-overlay>
</template>
