/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import AccountSettingsDialog from '@/features/account/components/AccountSettingsDialog.vue';
import AddPaymentMethodDialog from '@/features/billing/components/AddPaymentMethodDialog.vue';
import { EditOrderDialog } from '@/features/campaigns/components/orders';
import { ViewOrderDialog } from '@/features/campaigns/components/orders/ViewOrderDialog';
import { ViewInvoiceDialog } from '@/features/invoices/components/ViewInvoiceDialog';
import OrderRunPutOnHoldDialog from '@/features/orderRuns/components/OrderRunPutOnHoldDialog.vue';
import OrderRunResumeHoldDialog from '@/features/orderRuns/components/OrderRunResumeHoldDialog.vue';
import ViewOrderRunDialog from '@/features/orderRuns/components/ViewOrderRunDialog.vue';
import CreateLinkedRunDialog from '@/features/orders/components/CreateLinkedRunDialog.vue';
import ReviewOrderDialog from '@/features/orders/components/ReviewOrderDialog.vue';
import ShareOrderDialog from '@/features/orders/components/ShareOrderDialog.vue';
import type { Component } from 'vue';
import CreateCampaignDialog from '../campaigns/components/campaigns/CreateCampaignDialog.vue';
import { CreateCreativeDialog, EditCreativeDialog } from '../creatives/components';
import CreateScreenDialog from '../screens/components/CreateScreenDialog.vue';
import EditScreenDialog from '../screens/components/EditScreenDialog.vue';
import InviteUserDialog from '../users/components/InviteUserDialog.vue';

/** Register */
export const REGISTERED_DIALOGS = {
    createLinkedRun: CreateLinkedRunDialog,
    reviewOrder: ReviewOrderDialog,
    editOrder: EditOrderDialog,
    createCampaign: CreateCampaignDialog,
    createCreative: CreateCreativeDialog,
    editCreative: EditCreativeDialog,
    viewInvoice: ViewInvoiceDialog,
    viewOrder: ViewOrderDialog,
    viewOrderRun: ViewOrderRunDialog,
    putRunOnHold: OrderRunPutOnHoldDialog,
    resumeRun: OrderRunResumeHoldDialog,
    inviteUser: InviteUserDialog,
    addPaymentMethod: AddPaymentMethodDialog,
    accountSettings: AccountSettingsDialog,
    shareOrderSummaryLink: ShareOrderDialog,
    createScreen: CreateScreenDialog,
    editScreen: EditScreenDialog,
} as const satisfies Record<string, Component>;

export const REGISTERED_DIALOG_NAMES = Object.keys(REGISTERED_DIALOGS) as (keyof typeof REGISTERED_DIALOGS)[];

export type RegisteredDialogName = keyof typeof REGISTERED_DIALOGS;
