import { useApi } from '@/features/api';
import type { Ref } from 'vue';
import { computed, toValue } from 'vue';

const STALE_TIME = 2500;
const GC_TIME = 5000;

export function useScreen(screenId: number | Ref<number>) {
    const isEnabled = computed(() => !!toValue(screenId));

    const { isLoading, data, error } = useApi(['screens/GET_SCREEN', screenId], (endpoint, fetcher) => {
        return fetcher(endpoint, { screenId: toValue(screenId)! });
    }, { staleTime: STALE_TIME, cacheTime: GC_TIME, enabled: isEnabled });

    return {
        isLoading,
        screen: data,
        errors: error,
    };
}
